import {JsonAdapter} from "../helpers/JsonAdapter";

export class NavigationItem {
  title: string = "";
  routerLink: string = "";
  icon: string = "";
  class?: string = "";
  tooltip: string = "";
  visibleIfGuest = false;
  visibleIfMember = false;
  hiddenInternally = false;
  hiddenExternally = false;
  hiddenForRole: string[] = [];
  callbackFn = "";
  routerLinkActiveOptions: any = null;
  showNotificationBadge = false;

  static create(object:any): NavigationItem {
    const item = JsonAdapter.fill(new NavigationItem(), object);

    item.routerLinkActiveOptions = object.routerLinkActiveOptions;
    item.hiddenForRole = object.hiddenForRole || [];

    return item;
  }
}
