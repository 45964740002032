import {JsonAdapter} from "../helpers/JsonAdapter";
import {UserProfile} from "./UserProfile";
import {EnvironmentService} from "../services/environment.service";

export class User {

  id: string = "";
  first_name: string = "";
  last_name: string = "";
  fullname: string = "";
  email: string = "";
  roles: string[] = [];
  profile!: UserProfile;
  username: string = "";
  check_number: string = "";
  last_login_at: string = "";

  static create(object:any, env: EnvironmentService | null = null): User {
    const item = JsonAdapter.fill(new User(), object);
    if(object.roles) {
      item.roles = object.roles;
    }

    if(object.profiles && object.profiles.length) {
      if(object.profiles[0].profile_type && object.profiles[0][object.profiles[0].profile_type]) {
        item.profile = UserProfile.create(object.profiles[0][object.profiles[0].profile_type], env);
      } else {
        item.profile = UserProfile.create(object.profiles[0], env);
      }
      item.profile.roles = [...item.profile.roles, ...item.roles];
    } else if(object.profile) {
      item.profile = UserProfile.create(object.profile, env);
    }else if(object.roles && object.roles.includes("admin")) {
      item.profile = new UserProfile();
      item.profile.roles = item.roles;
    }

    return item;
  }

  static createMultiple(objects: User[], env: EnvironmentService | null = null): User[] {
    const items = [];
    for (let i = 0; i < objects.length; i++) {
      items.push(User.create(objects[i], env));
    }
    return items;
  }

  get name() {
    if(this.first_name && this.last_name) {
      return this.first_name + " " + this.last_name;
    } else {
      return this.fullname;
    }
  }

  get avatar() {
    if(this.profile)
      return this.profile.avatar;

    return "/assets/images/profiles/placeholder.svg";
  }

  get idAsObject() {
    return {id: this.id};
  }
}
