import { Injectable } from '@angular/core';
import * as dayjs from 'dayjs';
import {HttpClient} from "@angular/common/http";
import {User} from "../../models/User";
import {map, mergeMap, shareReplay, tap} from "rxjs/operators";
import jwt_decode from 'jwt-decode';
import {UserProfile} from "../../models/UserProfile";
import {BehaviorSubject, forkJoin, Observable, of} from "rxjs";
import {Router} from "@angular/router";
import {LocalStorageService} from "../../services/local-storage.service";
import {AppComponent} from "../../../app.component";
import {CookieService} from "ngx-cookie";
import {EnvironmentService} from "../../services/environment.service";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public currentUser$: BehaviorSubject<UserProfile|null> = new BehaviorSubject<UserProfile|null>(null);
  public currentUserId: string = "";
  public currentUserProfileId: string = "";
  static VALIDATIONS = {
    UNREGISTERED: "unregistered"
  };

  constructor(private http: HttpClient, private router: Router, private cookieService: CookieService, private environment: EnvironmentService) {

    // Try to renew the token in every 10 minutes if the remeaning time is after the half of this expiration time.
    AppComponent.isBrowser.subscribe(isBrowser => {
      if (isBrowser) {
        setInterval(() => {
          this.renewToken();
        }, 600 * 1000);

        this.renewToken();
      }
    });
  }

  login(credentials: {email: string, password: string}) {
    return this.http.post<User>(this.environment.apiBaseUrl + 'auth/login', credentials)
      .pipe(
        tap(res => {
          this.setSession(res);
        }),
        mergeMap(result1 => this.fetchCurrentUserData()),
      );
  }

  loginWithToken(token: string) {
    this.setSession(token);
    return this.fetchCurrentUserData();
  }

  private setSession(authResult: any) {
    const tokenData = this.getDecodedAccessToken(authResult.token);
    const expiresAt = dayjs(tokenData.exp * 1000);

    this.cookieService.put('access_token', authResult.token);
    this.cookieService.put("expires_at", JSON.stringify(expiresAt.valueOf()) );
  }

  logout() {
    const hasToken = this.cookieService.hasKey('access_token');

    this.cookieService.remove("access_token");
    this.cookieService.remove("expires_at");

    this.currentUser$.next(null);
    this.currentUserId = "";
    this.currentUserProfileId = "";

    // we do not redirect when there is no access token because the user was not logged in before
    if(hasToken) {
      this.router.navigate(['/account/sign-in']);
    }
  }

  public getToken() {
    return this.cookieService.get('access_token');
  }

  public isLoggedIn() {
    return dayjs().isBefore(this.getExpiration());
  }

  isLoggedOut() {
    return !this.isLoggedIn();
  }

  getExpiration() {
    const expiration = this.cookieService.get("expires_at");

    if(!expiration) {
      return dayjs().subtract(1, 'days');
    }

    const expiresAt = JSON.parse(expiration);
    return dayjs(expiresAt);
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch(Error) {
      return null;
    }
  }


  // Logged In user data

  fetchCurrentUserData() {
    const accessToken = this.cookieService.get('access_token');

    if(accessToken) {
      const tokenData = this.getDecodedAccessToken(accessToken);
      const userId = tokenData.id;

      this.currentUserId = userId;

        console.log("token", tokenData);
      if(tokenData && tokenData.profiles && tokenData.profiles.length > 0) {
        this.currentUserProfileId = tokenData.profiles[0];
      }

      return this.http.get<User>(this.environment.apiBaseUrl + 'user/' + userId).pipe(
        tap(res => {
          this.refreshCurrentUser(res);
        }),
        map(res => true)
      );
    }

    return of(false);
  }

  refreshCurrentUser(value: User) {
    this.currentUser$.next(User.create(value, this.environment).profile);
  }

  getCurrentUser() {
    return this.currentUser$.getValue();
  }

  loginAs(userProfile: UserProfile) {
    const accessToken = this.cookieService.get('access_token');

    const credentials = {
      token: accessToken,
      userid: userProfile.user.id
    };

    return this.http.post<User>(this.environment.apiBaseUrl + 'auth/token', credentials)
      .pipe(
        tap(res => {
          this.setSession(res);
        }),
        mergeMap(result1 => this.fetchCurrentUserData()),
      );
  }

  renewToken() {

    if (!this.isLoggedIn()) {
      this.logout();
      return;
    }

    // check token if its half of the ttl we need to renew it
    const accessToken = this.cookieService.get('access_token');

    if(!accessToken) {
      this.logout();
      return;
    }

    const jwtData = this.getDecodedAccessToken(accessToken);
    const diff = jwtData.exp - jwtData.iat;
    const now = Math.floor(Date.now() / 1000);

    console.log(jwtData.exp - diff / 2, " > ", now, jwtData.exp, diff);

    /*if (jwtData.exp - diff / 2 > now) {
      return;
    }*/

    this.http.post(this.environment.apiBaseUrl + 'auth/token', {token: accessToken})
      .subscribe(result => {
        this.setSession(result);
      }, error => {
        this.logout();
      });
  }

  resetPassword(email: string) {
    return this.http.post(this.environment.apiBaseUrl + 'auth/reset-password', {email: email});
  }

  validateEmail(token: string) {
    return this.http.get(this.environment.apiV1BaseUrl + 'user/validate', {params: {validation_hash: token}});
  }

  hasRole(role: string, includeAdmin: boolean = true) {
    return this.getCurrentUser()?.hasRole(role, includeAdmin);
  }

  isIndividual() {
    return this.getCurrentUser()?.isIndividual();
  }

  isStudent() {
    return this.getCurrentUser()?.isStudent();
  }

  isCompany() {
    return this.getCurrentUser()?.isCompany();
  }

  isInstitute() {
    return this.getCurrentUser()?.isInstitute();
  }

  isOrganisation() {
    return this.getCurrentUser()?.isOrganisation();
  }

  isAdmin() {
    return this.getCurrentUser()?.isAdmin();
  }

  getCurrentUserProfileId() {
    return this.currentUserProfileId;
  }

  getCurrentUserOrganisationId() {
    return this.getCurrentUser()?.organisation?.id || '';
  }

  getCurrentUserOrganisationSlug() {
    return this.getCurrentUser()?.organisation?.slug || '';
  }

  getCurrentUserId() {
    return this.currentUserId;
  }

  isSignUpComplete() {
    return this.getCurrentUser()?.signup_complete;
  }

  // ROLES
  isOrganisationManager() {
    return this.hasRole('organisation_manager');
  }

  isOrganisationGroupManager() {
    const user = this.getCurrentUser();
    if (this.hasRole('corporate_manager') && user && user.manager_for_organisations?.length > 0) {
      return true;
    }

    return false;
  }

  getManagedOrganisations() {
    const organisations = this.getCurrentUser()?.manager_for_organisations || [];
    return organisations.map(org => {
      org.name = org.name.replace(/\(part of the NDA Group\)/g, '');
      return org;
    });
  }
}
